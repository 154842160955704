import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Logo from '../Logo';
import Button from '../Button';
import { IfOpen } from '../OpenFlags';

class Header extends React.Component {
  static propTypes = {
    maker: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['light', 'dark']),
    showJoinBtn: PropTypes.bool,
  };
  static defaultProps = {
    maker: '',
    type: 'light',
    showJoinBtn: true,
  };

  render() {
    const { maker, type, showJoinBtn } = this.props;

    return (
      <div className="container mx-auto pt-8 md:flex text-center md:text-left">
        <div className="flex-1 ml-2 mb-4 md:mb-0 md:ml-0">
          <Logo type={type} />
        </div>
        <div className="mr-2 md:mr-0">
          <a
            href="https://www.themakersmob.com/login"
            className={`${
              type === 'dark' ? 'text-grey-darkest' : 'text-grey'
            } mr-4 text-sm opacity-75 hover:opacity-100`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Already a member? Login here
          </a>
          <IfOpen>
            {showJoinBtn && (
              <Button onClick={e => navigate(`/plans?ref=${maker}`)}>
                Join Now
              </Button>
            )}
          </IfOpen>
        </div>
      </div>
    );
  }
}

export default Header;
