import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import qs from 'qs';

import { isBrowser } from '../../utils';

const IsClosed = ({ children, flag, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteStatusQuery {
          site {
            siteMetadata {
              isClosed
            }
          }
        }
      `}
      render={data => {
        const { isClosed } = data.site.siteMetadata;
        const query = get(global, 'location.search', '').replace('?', '');
        const params = qs.parse(query);
        const openOverride = !!params.open;

        if(!isBrowser()) {
          return null;
        }

        // Allow url param "open" to override the site config.
        if (openOverride && !flag) {
          return <React.Fragment>{children}</React.Fragment>;
        }

        if (openOverride && flag) {
          return null;
        }

        if (isClosed === flag) {
          return <React.Fragment>{children}</React.Fragment>;
        } else {
          return null;
        }
      }}
    />
  );
};

const IfClosed = ({ children }) => <IsClosed flag={true}>{children}</IsClosed>;
const IfOpen = ({ children }) => <IsClosed flag={false}>{children}</IsClosed>;

export { IfOpen, IfClosed };
export default IsClosed;
