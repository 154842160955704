// TODO: Create plans and links for ALL THE MAKERS!
export const getMakerDetails = maker => {
  switch (maker) {
    case 'samurai':
      return {
        id: 'samurai',
        name: 'Samurai Carpenter',
        single:
          'https://app.paykickstart.com/checkout/18190?maker=SamuraiCarpenter&plan=samurai-only',
        allAccess:
          'https://app.paykickstart.com/checkout/18189?maker=SamuraiCarpenter&plan=all-access',
      };
    case 'diresta':
      return {
        id: 'diresta',
        name: 'Jimmy DiResta',
        single:
          'https://app.paykickstart.com/checkout/18187?maker=DiResta&plan=diresta-only',
        allAccess:
          'https://app.paykickstart.com/checkout/18186?maker=DiResta&plan=all-access',
      };
    case 'peters':
      return {
        id: 'peters',
        name: 'Jon Peters',
        single:
          'https://app.paykickstart.com/checkout/18192?maker=JonPeters&plan=jon-peters-only',
        allAccess:
          'https://app.paykickstart.com/checkout/18191?maker=JonPeters&plan=all-access',
      };
    case 'april-wilkerson':
      return {
        id: 'april-wilkerson',
        name: 'April Wilkerson',
        // FIXME: This link is wrong, it's Jon Peters.
        // single: 'https://app.paykickstart.com/checkout/18192?maker=AprilWilkerson&plan=april-wilkerson-only',
        allAccess:
          'https://app.paykickstart.com/checkout/19147?maker=AprilWilkerson&plan=all-access',
      };
    default:
      // When there's no maker referring the traffic - ex: homepage.
      return {
        id: 'none',
        name: null,
        allAccess: 'https://app.paykickstart.com/checkout/18184',
      };
  }
};

export const handleJoinClick = (e, maker = '', allAccess = true) => {
  const makerDetails = getMakerDetails(maker);
  const href = makerDetails[allAccess ? 'allAccess' : 'single'];
  e.preventDefault();
  global.location.href = href;
};

export const redirect = url => {
  global.location.href = url;
  return;
};

export const makerGetRewardfulId = maker => {
  switch (maker) {
    case 'samurai':
      return 'jesse';
    case 'diresta':
      return 'jimmy';
    case 'peters':
      return 'jon';
    default:
      return null;
  }
};

export const isBrowser = () => typeof window !== 'undefined';
