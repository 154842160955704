import React from 'react';
import PropTypes from 'prop-types';

import logoImgDark from '../../imgs/logo_makers_mob_dark.png';
import logoImgLight from '../../imgs/logo_makers_mob_light.png';

const Logo = ({ className, type }) => {
  return (
    <React.Fragment>
      <img
        src={type === 'dark' ? logoImgDark : logoImgLight}
        alt="Makers Mob"
        className={'w-48 ' + className}
      />
    </React.Fragment>
  );
};
Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['light', 'dark']),
};
Logo.defaultProps = {
  type: 'light',
};

export default Logo;
