import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Logo from '../Logo';

const Footer = ({ type }) => {
  const year = new Date().getFullYear();
  const headerTextColor = type === 'light' ? 'text-white' : 'text-grey-darkest';

  return (
    <div className="mt-32 mb-12">
      <div className="container mx-auto md:flex text-center md:text-left">
        <div className="flex-1 mb-8 md:mb-0">
          <Logo type={type} />
          <div className="mt-2 text-sm text-grey-dark">
            &copy; Copyright {year} - All Rights Reserved.
          </div>
        </div>
        <div className="flex-1 text-center md:text-right">
          <div className={`${headerTextColor} text-lg mb-4`}>
            Customer Support
          </div>
          <ul className="list-reset">
            <li className="mb-1">
              {/*<Link
                to="/"
                className="no-underline text-md text-grey-dark hover:underline"
              >
                Home
              </Link>*/}
            </li>
            <li className="mb-1">
              <Link
                to="/tos"
                className="no-underline text-md text-grey-dark hover:underline"
              >
                Terms &amp; Conditions
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/privacy"
                className="no-underline text-md text-grey-dark hover:underline"
              >
                Privacy Policy
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/support"
                className="no-underline text-md text-grey-dark hover:underline"
              >
                Contact Us
              </Link>
            </li>
            <li className="mb-1">
              <a
                href="https://www.themakersmob.com/login"
                className="no-underline text-md text-grey-dark hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Already a member?
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
Footer.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']),
};
Footer.defaultProps = {
  type: 'light',
};

export default Footer;
