import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, type, className, ...rest }) => {
  let styles;
  switch (type) {
    case 'cta':
      styles =
        'bg-green hover:bg-green-light active:bg-green-dark border border-green';
      break;
    case 'ghost':
      styles = 'border border-white opacity-75 hover:opacity-100';
      break;
    default:
      styles = 'bg-green hover:bg-green-light active:bg-green-dark';
      break;
  }
  return (
    <button
      className={`appearance-none rounded px-4 py-2 text-white uppercase antialiased text-sm font-bold ${styles} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['cta', 'ghost']),
};
Button.defaultProps = {
  type: 'cta',
};

export default Button;
