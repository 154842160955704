import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Footer from '../components/Footer';
import './Layout.css';

const description = `Makers Mob is the web's premier school for all Makers. Learn Craftsmanship from the master YouTube Makers.`;
const siteTitle = 'Makers Mob - Exclusive Makers Community & School';
const shareImg = 'https://www.joinmakersmob.com/sharable.png';
const url = 'https://www.joinmakersmob.com';

const Layout = ({ children, pageTitle, type }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      const bgColor = type === 'dark' ? 'bg-black' : 'bg-grey-lightest';
      // Ya this is dumb, but the setting is opposite for the footer.
      const footerType = type === 'dark' ? 'light' : 'dark';

      return (
        <React.Fragment>
          <Helmet
            title={
              pageTitle
                ? `${pageTitle} - ${data.site.siteMetadata.title}`
                : data.site.siteMetadata.title
            }
            meta={[
              { name: 'description', content: description },
              // Google
              { itemprop: 'name', content: siteTitle },
              { itemprop: 'description', content: description },
              { itemprop: 'image', content: shareImg },
              // Facebook
              { property: 'og:url', content: url },
              { property: 'og:type', content: 'website' },
              { property: 'og:title', content: pageTitle },
              { property: 'og:description', content: description },
              { property: 'og:image', content: shareImg },
              // Twitter
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:title', content: pageTitle },
              { name: 'twitter:description', content: description },
              { name: 'twitter:image', content: shareImg },
            ]}
          >
            <html lang="en" />
            <script async src='https://r.wdfl.co/rw.js' data-rewardful='0e8371'></script>
          </Helmet>
          <div
            className={`font-sans ${bgColor} text-base`}
            style={{ fontFamily: 'Open Sans, Sans-serif' }}
          >
            <div className="h-full">
              {children}
              <Footer type={footerType} />
            </div>
          </div>

        </React.Fragment>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['light', 'dark']),
};

Layout.defaultProps = {
  type: 'light',
};

export default Layout;
